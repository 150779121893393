import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from "axios";
import configData from "../../config.json";
import GlobalSearch from "../common/globalSearch/globalSearch";

const Footer = () => {
  const [searchData, setSearchData] = useState([]);
  const [footerMenuData, setFooterMenuData] = useState([]);
  const [socialMenuData, setSocialMenuData] = useState([]);
  const [privacyPolicy, setPrivacyPolicy] = useState("");

  const path = window.location.pathname.includes("/news/") ? "../" : "./";
  const getMenuContent = () => {
    axios.get(configData.API_URL + "menu").then((response) => {
      const footerMenu = response.data.data.find(
        (item) => item.sku === "FOOTER_MENU"
      );
      const socialMenu = response.data.data.find(
        (item) => item.sku === "FOOTER_SOCIAL_MENU"
      );
      if (footerMenu) {
        setFooterMenuData(JSON.parse(footerMenu.body));
      }
      if (socialMenu) {
        setSocialMenuData(JSON.parse(socialMenu.body));
      }
    });
  };

  const getPrivacyPolicyContent = () => {
    axios.get(configData.API_URL + "section").then((response) => {
      const ploicy = response.data.data.find(
        (item) => item.title === "Cookie Policy"
      );
      if (ploicy) {
        setPrivacyPolicy(ploicy.body);
      }
    });
  };

  useEffect(() => {
    getPrivacyPolicyContent();
    getMenuContent();
  }, []);

  return (
    <>
      <footer className="fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="footer-link">
                <ul>
                  {footerMenuData.length > 0 &&
                    footerMenuData.map((elem, index) => (
                      <li key={`footer_link_${index}`}>
                        <a href={`${path}${elem.url}`}>{elem.label}</a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="footer-social-link">
                <ul>
                  <li>
                    <span>Follow Us</span>
                  </li>
                  {socialMenuData.length > 0 &&
                    socialMenuData.map((item, index) => (
                      <li key={`social_link_${index}`}>
                        <a href={item.url} target="_blank">
                          <i className={item.icon} aria-hidden="true"></i>
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-copyright">
        <p className="footer-company">
          All Rights Reserved. &copy; {new Date().getFullYear()}
          <a href="https://bydautoindia.com">BYD</a>
          <br />
          <a href={`${path}terms-conditions`}>Terms & Conditions</a>
        </p>
      </div>
      <div className="popup-wrapper">
        <div className="popup-body">
          <div
            className=""
            dangerouslySetInnerHTML={{ __html: privacyPolicy }}
          ></div>
          <button
            type="button"
            className="load-more"
            onClick={() => {
              window.setCookie("byds", "byd cars", 30);
              let popupWrapper = document.querySelector(".popup-wrapper");
              popupWrapper.classList.remove("active");
              // $('.popup-wrapper').removeClass('active');
            }}
            style={{ fontSize: "12px" }}
          >
            I Accept
          </button>
        </div>
      </div>
      <div className="detailed-menu ">
        {/* <div className="virtual-toure">
                    <i className="icon-close close-virtual-toure"></i>
                    <iframe src="https://byd.acktec.sg/tutorial/verge3d/BYD_e6/BYD_e6_index" className="virtual-toure-frame"></iframe>
                </div> */}
        <GlobalSearch />
        <div className="menu-dropdown-wrapper">
          <i className="icon-close close-menu-wrapper"></i>
          <nav className="dropdown-link">
            <ul>
              <li className="sub-nav-toggler">
                <a href="">Models</a>
                <ul className="sub-nav">
                  <li>
                    <a href={`${path}bydatto3`}>BYD ATTO 3</a>
                  </li>
                  <li>
                    <a href={`${path}bydemax7`}>BYD eMAX 7</a>
                  </li>
                  <li>
                    <a href={`${path}bydseal`}>BYD SEAL</a>
                  </li>
                </ul>
              </li>
              {/* <li>
                            <a href={`${path}blade-battery`}>Blade Battery</a>
                        </li> */}
              <li className="sub-nav-toggler">
                <a href="">Technology</a>
                <ul className="sub-nav">
                  <li>
                    <a href={`${path}blade-battery`}>Blade Battery</a>
                  </li>
                  <li>
                    <a href={`${path}eplatform3.0`}>e-Platform 3.0</a>
                  </li>
                </ul>
              </li>
              <li className="sub-nav-toggler">
                <a href={`${path}about_byd`}>About BYD</a>
                <ul className="sub-nav">
                  <li>
                    <a href={`${path}about_byd?#bydintro`}>BYD Intro</a>
                  </li>
                  <li className="sub-nav-inner-toggler">
                    <span>Global Corporate</span>
                    <ul className="sub-nav-inner">
                      <li>
                        <a href={`${path}about_byd?#globalization`}>
                          Globalization
                        </a>
                      </li>
                      <li>
                        <a href={`${path}about_byd?#diversification`}>
                          Diversification
                        </a>
                      </li>
                      <li>
                        <a href={`${path}about_byd?#marketperformance`}>
                          Market Performance
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="sub-nav-inner-toggler">
                    <span>Technology-Oriented</span>
                    <ul className="sub-nav-inner">
                      <li>
                        <a href={`${path}about_byd?#auto`}>Auto</a>
                      </li>
                      <li>
                        <a href={`${path}about_byd?#eplatform`}>
                          ePlatform 3.0
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="sub-nav-inner-toggler">
                    <span>Green Future</span>
                    <ul className="sub-nav-inner">
                      <li>
                        <a href={`${path}about_byd?#cooltheearth`}>
                          Cool the Earth by 1&deg;C
                        </a>
                      </li>
                      <li>
                        <a href={`${path}about_byd?#pollutioncontrol`}>
                          Pollution Control
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="sub-nav-inner-toggler">
                    <span>Corporate Presence</span>
                    <ul className="sub-nav-inner">
                      <li>
                        <a href={`${path}about_byd?#csr`}>CSR</a>
                      </li>
                      <li>
                        <a href={`${path}about_byd?#ar`}>
                          Corporate&nbsp;Governance
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <a href={`${path}news`}>News</a>
              </li>
              <li>
                <a href={`${path}events`}>Events</a>
              </li>
              <li className="sub-nav-toggler">
                <a href="">Service</a>
                <ul className="sub-nav">
                  <li>
                    <a href={`${path}extended-warranty`}>Warranty</a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <HelmetProvider>
        <Helmet>
          <script src="/script/main.js"></script>
        </Helmet>
      </HelmetProvider>
    </>
  );
};
export default Footer;
